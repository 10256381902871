:root {
  --danger: #ff5561;
  --bg: #fff6f7;
  --bg-muted: #ff556130;

  --warning: #5571ff;
  --bg-warning: #f6faff;
  --bg-muted: #55a1ff30;
}

body {
  margin: 0;
  font-family: 'Gotham', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
  background-color: var(--bg);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #111111;
}

.app__terra {
  text-align: center;
  background-color: var(--bg-warning);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #111111;
}

.App h2 {
  margin: 5px 69px 5px 71px;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.78px;
  color: var(--danger);
}

.app__terra h2 {
  margin: 20px 69px -10px 71px;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.78px;
  color: var(--warning);
}

.App p {
  margin: 10px 0 30px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.44px;
  text-align: center;
  color: #111;
}

.url {
  margin: 10px 0 40px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.44px;
  text-align: center;
  color: #111;
}

.App img, .app__terra img {
  width: 4.8rem;
}

.button__main {
  text-decoration: none;
  font-family: 'Gotham', sans-serif;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: var(--bg);
  background-color: var(--danger);

  padding: .9rem 2.2rem;
  border-radius: 30px;
  border: 1px solid var(--danger);
}

.button__main:hover {
  color: var(--danger);
  background-color: var(--bg-muted);
}

.App a {
  color: var(--danger);
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
  margin-top: 1rem;
}

.app__terra a {
  color: var(--warning);
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
  margin-top: 1rem;
}

a:hover {
  text-decoration: underline;
}